.navigation-ajax-container {
	position: absolute;
	font-size: 16px;
	line-height: 60px;
	text-transform: none;
}

.navigation-ajax-container .ddc-icon {
	display: inline-block;
	float: left;
	margin-right: 10px;
	animation: spin 3s infinite linear;
}

.ddc-icon-search {
	pointer-events: none;
}

.ajax-navigation-element {
	visibility: hidden;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(359deg); }
}
